<ng-container *ngIf="isLoading">
  <app-loader></app-loader>
</ng-container>

<div class="modal-header">
  <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss()">
    Close
  </button>
  <h4 class="modal-title text-center" id="modal-title">
    {{ !storeId ? 'Create New Store' : 'Edit Store' }}
  </h4>
</div>
<div class="modal-body">
  <form name="storeForm" #storeForm="ngForm">
    <div class="form-row justify-content-center">
      <div class="col-10">
        <div class="form-group">
          <label for="name">Name*</label>
          <input
            type="text"
            class="form-control"
            id="name"
            name="name"
            placeholder="Name"
            autocomplete="off"
            [(ngModel)]="store.name"
            required
            maxlength="100"
          />
        </div>

        <div class="form-group">
          <label for="url">Store Url*</label>
          <input
            type="text"
            class="form-control"
            id="url"
            name="url"
            placeholder="Store Url"
            autocomplete="off"
            [(ngModel)]="internalUrl"
            required
            #url="ngModel"
            maxlength="100"
          />
          @if(url.errors?.duplicate){
          <div class="invalid-feedback">Url already in use</div>
          }
        </div>

        <div class="form-group">
          <label for="storeType">Store Type*</label>
          <ng-select
            id="storeType"
            name="Store Type"
            placeholder="Store Type"
            [items]="storeTypesOptions"
            bindLabel="name"
            [(ngModel)]="store.storeType"
            autocomplete="off"
            required
            [clearable]="false"
            [style.position]="'relative'"
          >
          </ng-select>
        </div>

        <div class="form-group">
          <label for="StoreCurrency">Store Currency*</label>
          <ng-select
            id="StoreCurrency"
            name="Store Currency"
            placeholder="Store Currency"
            [items]="currencies"
            bindLabel="name"
            [(ngModel)]="currency"
            autocomplete="off"
            required
            [clearable]="false"
            [style.position]="'relative'"
          >
          </ng-select>
        </div>

        <div class="form-group">
          <label for="ClearCartPolicy">Cart clear policy</label>
          <ng-select
            id="ClearCartPolicy"
            name="Clear Cart Policy"
            [(ngModel)]="cartClearPolicy"
            autocomplete="off"
            [clearable]="false"
          >
            <ng-option *ngFor="let item of cartClearPolicyList | keyvalue" [value]="item.key">{{
              item.value
            }}</ng-option>
          </ng-select>
        </div>
        <div class="form-group">
          <label for="ManagementPolicy">Stock Management policy</label>
          <ng-select
            id="ManagementPolicy"
            name="Management policy"
            [(ngModel)]="stockManagementPolicy"
            autocomplete="off"
            [clearable]="false"
          >
            <ng-option *ngFor="let item of managementListPolicy | keyvalue" [value]="item.key">{{
              item.value
            }}</ng-option>
          </ng-select>
        </div>
        <div class="form-group">
          <label for="broadcastResolution">Broadcast Resolution</label>
          <ng-select
            id="broadcastResolution"
            name="Broadcast resolution"
            [(ngModel)]="broadcastResolution"
            autocomplete="off"
            [clearable]="false"
          >
            @for (item of broadcastResolutionSelect | keyvalue; track item.key) {
            <ng-option [value]="item.key">{{ item.value }}</ng-option>
            } @empty {
            <ng-option disabled>No resolutions available</ng-option>
            }
          </ng-select>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="haveTheme"
              name="haveTheme"
              [(ngModel)]="haveTheme"
            />
            <label class="custom-control-label" for="haveTheme">Have Theme</label>
          </div>
        </div>

        <div *ngIf="haveTheme" class="form-group">
          <label for="primary">Primary Color</label>
          <input
            type="color"
            class="form-control"
            id="primary"
            name="primary"
            placeholder="Store primary color"
            autocomplete="off"
            [(ngModel)]="theme.primaryColor"
          />
        </div>

        <div *ngIf="haveTheme" class="form-group">
          <label for="secondary">Secondary Color</label>
          <input
            type="color"
            class="form-control"
            id="secondary"
            name="secondary"
            placeholder="Store secondary color"
            autocomplete="off"
            [(ngModel)]="theme.secondaryColor"
          />
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="isPublished"
              name="isPublished"
              [(ngModel)]="store.isPublished"
            />
            <label class="custom-control-label" for="isPublished">Is Published</label>
          </div>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="pictureInPicture"
              name="pictureInPicture"
              [(ngModel)]="pictureInPicture"
            />
            <label class="custom-control-label" for="pictureInPicture">Picture in picture</label>
          </div>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="mirrorHost"
              name="mirrorHost"
              [(ngModel)]="mirrorHost"
            />
            <label class="custom-control-label" for="mirrorHost">Mirror host</label>
          </div>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="isAutoCheckoutEnabled"
              name="isAutoCheckoutEnabled"
              [(ngModel)]="store.isAutoCheckoutEnabled"
            />
            <label class="custom-control-label" for="isAutoCheckoutEnabled">Auto Checkout</label>
          </div>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="embedPostSession"
              name="embedPostSession"
              [(ngModel)]="embedPostSession"
            />
            <label class="custom-control-label" for="embedPostSession">Embed Post Session</label>
          </div>
        </div>
        <div class="form-group">
          <label class="custom-control" for="embedPreSessionTimeFrame"
            >Hours Before Live Session to Embed</label
          >
          <div class="custom-control">
            <input
              type="number"
              class="form-control"
              id="embedPreSessionTimeFrame"
              name="embedPreSessionTimeFrame"
              placeholder="24"
              autocomplete="off"
              [(ngModel)]="embedPreSessionTimeFrame"
            />
          </div>
        </div>
        <div class="form-group">
          <div>Feature Flags:</div>
          <div class="input-group ml-3">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="enableShoppableVideo"
                name="enableShoppableVideo"
                [(ngModel)]="enableShoppableVideo"
              />
              <label class="custom-control-label" for="enableShoppableVideo">Shoppable Video</label>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="requiresLoginByDefault"
              name="requiresLoginByDefault"
              [(ngModel)]="store.sessionActionsRequireLoginDefault"
            />
            <label class="custom-control-label" for="requiresLoginByDefault"
              >Session actions require login by default</label
            >
          </div>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="updateCoriunder"
              name="updateCoriunder"
              [(ngModel)]="updateCoriunder"
            />
            <label class="custom-control-label" for="updateCoriunder"
              >Set Coriunder Keys? (will rewrite current set up for the store)</label
            >
          </div>
        </div>

        <div *ngIf="updateCoriunder" class="form-group">
          <label for="merchantnumber">Merchant Number</label>
          <input
            type="number"
            class="form-control"
            id="merchantnumber"
            name="merchantnumber"
            placeholder="1234567"
            autocomplete="off"
            required
            [(ngModel)]="coriunder.merchantnumber"
          />
        </div>

        <div *ngIf="updateCoriunder" class="form-group">
          <label for="hashkey">Hash Key</label>
          <input
            type="password"
            class="form-control"
            id="hashkey"
            name="hashkey"
            placeholder="**********"
            autocomplete="off"
            required
            [(ngModel)]="coriunder.personalhashkey"
          />
        </div>
        <div class="form-group">
          <label for="External">Store External Url</label>
          <input
            type="url"
            class="form-control"
            id="External"
            name="External"
            placeholder="Store External Url"
            autocomplete="on"
            [(ngModel)]="externalUrl"
            maxlength="100"
          />
        </div>
        <div
          class="form-group"
          *ngIf="
            currency?.paymentSupplier === PaymentSuppliers.SDKWithRedirect ||
            currency?.paymentSupplier === PaymentSuppliers.WooCommerce
          "
        >
          <label for="checkoutRedirectRoute">Store Checkout Redirect Route</label>
          <input
            type="url"
            class="form-control"
            id="checkoutRedirectRoute"
            name="checkoutRedirectRoute"
            placeholder="/Route-Url"
            autocomplete="on"
            [(ngModel)]="store.checkoutRedirectRoute"
            maxlength="100"
          />
        </div>

        <div class="form-group" *ngIf="showStoreTokenField">
          <label for="utokenl">Store Token*</label>
          <input
            type="text"
            class="form-control"
            id="token"
            name="token"
            placeholder="Store Token"
            autocomplete="off"
            [(ngModel)]="token"
            minlength="3"
            maxlength="100"
          />
          <ng-container *ngIf="{getPassed: isT1CredentialsAreValid$ | async} as valuesObj">
            <span *ngIf="valuesObj.getPassed === null">Checking token...</span>
            <span *ngIf="valuesObj.getPassed === false" class="text-danger">
              Token is invalid {{ errorMessage ? ' - ' + errorMessage : '' }}
            </span>
            <span *ngIf="valuesObj.getPassed === true" class="text-success"> Token is valid </span>
          </ng-container>
        </div>

        @if(this.storeHasLinkedIntegration) {
        <div class="form-group">
          <label for="cronExp">Automatic Sync Schedule (CRON Expression)</label>
          <div id="edit-cron-div">
            <input
              type="text"
              class="form-control"
              id="cronExp"
              name="cronExp"
              placeholder="CRON expression"
              autocomplete="off"
              [(ngModel)]="this.store.cronScheduledSync"
              minlength="3"
              maxlength="100"
              disabled="true"
            />
            <button
              id="edit-cron-button"
              class="btn btn-yellow w-132"
              (click)="openCronExpressionEditModal()"
            >
              Edit
            </button>
          </div>
        </div>
        }
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-yellow w-132"
    (click)="save(storeForm)"
    [disabled]="isSubmitting"
  >
    Save
  </button>
</div>
