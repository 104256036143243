import normalizeUrl, {Options} from 'normalize-url';
import {v4 as uuidv4} from 'uuid';
import {
  getDomain,
  parse,
  getHostname,
  getPublicSuffix,
  getSubdomain,
  getDomainWithoutSuffix,
} from 'tldts';

type options = Options & {
  domain?: boolean;
  hostname?: boolean;
  publicSuffix?: boolean;
  subDomain?: boolean;
  domainWithoutSuffix?: boolean;
  removePath?: boolean;
  allInformationAtUrl?: boolean;
};

const shopifyPattern = /:\/\/[a-zA-Z0-9.-]+\.myshopify\.com/;

function isShopifySubdomain(storeUrl: string): boolean {
  return shopifyPattern.test(storeUrl);
}

export function sanitizeUrl(url: string, options?: options) {
  if (options?.allInformationAtUrl) {
    return parse(url);
  }
  if (options?.domain) {
    return getDomain(url);
  }
  if (options?.hostname) {
    const _url = normalizeUrl(url);
    return getHostname(_url);
  }
  if (options?.publicSuffix) {
    return getPublicSuffix(url);
  }
  if (options?.subDomain) {
    return getSubdomain(url);
  }
  if (options?.domainWithoutSuffix) {
    return getDomainWithoutSuffix(url);
  }

  if (options?.removePath) {
    try {
      if (isShopifySubdomain(url)) {
        return url;
      }
      const protocol = new URL(url).protocol;
      const parsedUrl = normalizeUrl(url);
      return `${protocol}//${parse(parsedUrl).hostname}`;
    } catch (e) {
      return null; //'Invalid URL';
    }
  }

  return normalizeUrl(url, options);
}
// added this feature in case needs to change the logic for randomly selecting a store name in the future
export function randomStoreUrl() {
  return uuidv4();
}
